<template>
  <div id="data-list-list-view" class="data-list-container">
    <h1>{{ $t("products") }}</h1>
    <br>
    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />
    <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="products">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- ADD NEW -->
          <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">{{ $t("Ajouter") }}</span>
          </div>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ products.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : products.length }} of {{ queriedItems }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=60">
              <span>60</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=80">
              <span>80</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="products">{{ $t("products") }}</vs-th>
        <vs-th sort-key="quantity">{{ $t("quantity") }}s</vs-th>
        <vs-th sort-key="addDate">{{ $t("addDate") }}</vs-th>
        <vs-th sort-key="UpDate">{{ $t("UpDate") }}</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.name}}</p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{tr.quantity}}</p>
          </vs-td>
          <vs-td>
            <vs-chip color="success">
              <vs-avatar icon-pack="feather" icon="icon-corner-up-right" />
              <p class="product-name font-medium truncate">{{tr.created_at | date_time}}</p>
              </vs-chip>
          </vs-td>
          <vs-td>
            <vs-chip color="warning">
              <vs-avatar icon-pack="feather" icon="icon-corner-down-left" />
              <p class="product-name font-medium truncate">{{tr.updated_at | date_time}}</p>
              </vs-chip>
          </vs-td>
          <vs-td class="whitespace-no-wrap">
            <div class="flex">
              <vx-tooltip text="Entrée" color="success">
                   <feather-icon
                    style="color: limegreen "
                    icon="PlusCircleIcon" 
                    svgClasses="w-5 h-5 hover:text-success stroke-current"
                    @click="activePrompt3 = true"
                    v-on:click="openUpstockTable(tr)"
                    color="success" 
                    type="border" class="ml-2" />
                 </vx-tooltip>
                 <vx-tooltip text="eortie" color="danger">
                   <feather-icon
                    style="color: red "
                    icon="MinusCircleIcon" 
                    svgClasses="w-5 h-5 hover:text-danger stroke-current"
                    @click="activePrompt4 = true" 
                    v-on:click="openUpstockTable(tr)"
                    color="danger" 
                    type="border" class="ml-2" />
                 </vx-tooltip>
                 <vx-tooltip text="Voir" color="primary">
               <feather-icon 
                   style="color: navy " 
                   icon="EyeIcon" 
                   svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    @click="activePrompt5 = true"
                    v-on:click="openPlayerDialog(tr)" 
                    color="warring" type="border"
                    class="ml-2" />
                </vx-tooltip>
              <vx-tooltip :text="$t('Modifier')" color="warning">
                <feather-icon
                  style="color: #FF9F43"
                  icon="EditIcon"
                  svgClasses="w-5 h-5 hover:text-warning stroke-current"
                  class="ml-2"
                  @click="editData(tr)"
                />
              </vx-tooltip>
              <vx-tooltip :text="$t('Supprimer')" color="danger">
                <feather-icon
                  @click="deleteData(tr.id)"
                  style="color: red"
                  icon="TrashIcon"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2"
                />
              </vx-tooltip>
            </div>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>

  <vs-prompt
    color="success"
    :title="$t('ReupStock')"
    @cancel=" newquantity= '' "
    @accept="UpStockTable"
    :accept-text= "this.$t('valider')"
    :cancel-text = "this.$t('Annuler')"
    @close="close"
    :is-valid="validateForm"
    :active.sync="activePrompt3">
    <div class="con-exemple-prompt">
      <small class="date-label">{{ $t("products") }}</small>
        <v-select 
                disabled="true"
                :options="products" 
                :reduce="rep => rep.id" 
                :clearable="true" 
                label="name"
                v-model="stockTab" 
                class="w-full mt-2" />
        <small class="date-label">{{ $t("Dispoquantity") }}</small>
        <vs-input
          disabled="true"
          v-model="quantityTab"
          class="w-full mt-2" 
        />
      <small class="date-label">{{ $t("Newquantity") }}<b style="color: #ff6141" >*</b></small>
      <vs-input-number
        min="0" 
        v-validate="'required'"
        name="newquantity"
        v-model="newquantity"
        class="w-full mt-2"/>
      <span  class="text-danger text-sm" v-show="errors.has('newquantity')">{{$t("quantity_error")}}</span>
     
    </div>
  </vs-prompt>

  <vs-prompt
    color="danger"
    :title="$t('DEdownStock')"
    @cancel=" newquantity= '' "
    @accept="DownStockTable"
    :accept-text= "this.$t('valider')"
    :cancel-text = "this.$t('Annuler')"
    @close="close"
    :is-valid="validateForm"
    :active.sync="activePrompt4">
    <div class="con-exemple-prompt">
      <small class="date-label">{{ $t("products") }}</small>
        <v-select 
                disabled="true"
                :options="products" 
                :reduce="rep => rep.id" 
                :clearable="true" 
                label="name"
                v-model="stockTab" 
                class="w-full mt-2" />
        <small class="date-label">{{ $t("Dispoquantity") }}</small>
        <vs-input
          disabled="true"
          v-model="quantityTab"
          class="w-full mt-2" 
        />
      <small class="date-label">{{ $t("Newquantity") }}<b style="color: #ff6141" >*</b></small>
      <vs-input-number
        min="0" 
        v-validate="'required'"
        name="newquantity"
        v-model="newquantity"
        class="w-full mt-2 "/>
      <span  class="text-danger text-sm" v-show="errors.has('newquantity')">{{$t("quantity_error")}}</span>
     
    </div>
  </vs-prompt>
  <vs-popup title="DÉTAILS "  :active.sync="activePrompt5">
    <div class="vx-row" v-if="selectedPlayer">
      <div class="vx-col sm:w-full  xs:w-full">
        <vs-input readonly :label="this.$t('stock') "  v-model=" selectedPlayer.name" class="mt-4 mb-2 w-full" />
        <vs-input readonly :label="this.$t('quantity') "  v-model="selectedPlayer.quantity" class="mt-4 mb-2 w-full" />
        <vs-input readonly :label="this.$t('stockType') "  v-model=" selectedPlayer.stockType.name " class="mt-4 mb-2 w-full" />
        <vs-input v-if="selectedPlayer.stockType.name ==='Produit'" readonly :label="this.$t('dosage') "  v-model="selectedPlayer.dosage" class="mt-4 mb-2 w-full" />
        <vs-input v-if="selectedPlayer.stockType.name ==='Produit'"  readonly :label="this.$t('indication')"  v-model=" selectedPlayer.indication" class="mt-4 mb-2 w-full" />
        <vs-input v-if="selectedPlayer.stockType.name ==='Produit'" readonly :label="this.$t('routeOfAdministration')"  v-model=" selectedPlayer.routeOfAdministration" class="mt-4 mb-2 w-full" />
        <vs-input v-if="selectedPlayer.stockType.name ==='Produit'" readonly :label="this.$t('contraindication')"  v-model=" selectedPlayer.contraindication" class="mt-4 mb-2 w-full" />
        <vs-input v-if="selectedPlayer.stockType.name ==='Produit'" readonly :label="this.$t('sideEffects')"  v-model=" selectedPlayer.sideEffects" class="mt-4 mb-2 w-full" />
        <small class="date-label">{{ $t("addDate") }}</small>
        <flat-pickr disabled :config="configdateTimePicker"  v-model="selectedPlayer.created_at" class=" mb-2 w-full" />
        <small class="date-label">{{ $t("UpDate") }}</small>
        <flat-pickr disabled :config="configdateTimePicker" v-model="selectedPlayer.updated_at" class=" mb-2 w-full" />
      
      </div>
    </div>
  </vs-popup>
  </div>
  
</template>
<script>

import DataViewSidebar from './DataViewSidebarapproProd.vue'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.min.css'
import {French as FrenchLocale} from 'flatpickr/dist/l10n/fr.js'


export default {
  components: {
    DataViewSidebar,
    flatPickr
  },
  data () {
    return {
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: FrenchLocale
      },
      id:'',
      newquantity:'',
      stock_check:false,
      activePrompt3:false,
      activePrompt4:false,
      activePrompt5:false,
      stockTab:'',
      quantityTab:'',
      products: [],
      stock:'',
      quantity:'',
      selected: [],
      itemsPerPage: 20,
      isMounted: false,
      addNewDataSidebar: false,
      selectedPlayer:undefined,
      sidebarData: {}
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.products.length
    },
    validateForm () {
      return !this.errors.any() && this.newquantity > 0
    },
    validateStockCheck () {
      return this.stock_check
    }
  },
  methods: {
    addNewData () {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },

    deleteData (id) {
    
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`stocks/${id}/`)
            .then(response => {
              this.getAllproducts()
              window.getPrendTaCom.success(this.$t('SuppressionStock'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('Suppression') })
            })
        }
      })

    },
 
    openUpstockTable (tr) {
      this.id = tr.id
      this.stockTab = tr.name
      this.quantityTab = tr.quantity
      this.newquantity = ''
    },
    openPlayerDialog (player) {
      this.selectedPlayer = player
    },
    UpStockTable () {
      this.$vs.dialog({
        type:'confirm',
        color: 'success',
        title: 'Confirmation',
        text: this.$t('confir_upd'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.post('enterInStocks/', { 
            stock:this.id,
            quantity:this.newquantity })
            .then(response => {
              this.getAllproducts()
              this.newquantity = ''
              window.getPrendTaCom.success(this.$t('stock_update'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('stock_faild') })
            })
        }
      })
    },
 
    DownStockTable () {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('confir_upd'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.post('outOfStocks/', { 
            stock:this.id,
            quantity:this.newquantity })
            .then(response => {
              this.getAllproducts()
              this.newquantity = ''
              window.getPrendTaCom.success(this.$t('stock_update'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('stock_faild') })
            })
        }
      })
    },
    editData (data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },

    toggleDataSidebar (val = false) {
      this.addNewDataSidebar = val
    },
  
    getAllproducts () {
      this.$vs.loading()
      this.$http.get('products/')
        .then((response) => {
          this.products = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }

  },
  async created () {
    window.products = this
    this.getAllproducts()
    document.title = 'MooziMed'


  },
  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          padding: 20px;
          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check{
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>


<style scoped>

</style>

<style scoped>

</style>
